<template>
  <div class="cart-detail-item clearfix">
    <div class="f-l item-left">
      <ng-image :src="data.logo_url" alt />

      <music-play-icon v-if="canPlay" is-mask :scope="data"></music-play-icon>
    </div>

    <div class="f-l item-right w-100">
      <ul class="right-inner flex-1-steady">
        <li class="ellipsis title font-md m-bottom-xs">
          <b>{{data.music_name}}</b>
          <slot name="top" :item="data"></slot>
        </li>
        <li class="m-bottom-sm lh-1">{{data.suplr_name}}</li>
        <!-- pc -->
        <li class="hidden-sm visible-md">
          <span class="m-right-sm">
            <span class="font-color-sub">{{$t('music.labelUseScene')}}</span>&nbsp;
            <span>{{data.auth_scene | toAuthScene}}</span>
          </span>
          <span class="m-right-sm">
            <span class="font-color-sub">{{$t('music.labelUseMethod')}}</span>&nbsp;
            <span>{{data.use_mode}}</span>
          </span>
          <span class="m-right-sm">
            <span class="font-color-sub">{{$t('music.labelAuthArea')}}</span>&nbsp;
            <span>{{data.auth_area | toArea}}</span>
          </span>
          <span class="m-right-sm">
            <span class="font-color-sub">{{$t('music.labelAuthTime')}}</span>&nbsp;
            <span>{{data.auth_time | toAuthPeriod}}</span>
          </span>
        </li>
        <!-- mobile -->
        <li class="visible-sm hidden-md">
          <span>{{data.auth_scene | toAuthScene}}；</span>
          <span>{{data.use_mode}}；</span>
          <span>{{data.auth_area | toArea}}；</span>
          <span>{{data.auth_time | toAuthPeriod}}；</span>
        </li>
        <li v-if="$slots.default">
          <slot></slot>
        </li>
      </ul>

      <div v-if="$slots.right" class="right f-l">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import {AuthAreaOptions, BusinessSceneOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'

  export default {
    data() {
      return {

      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      canPlay: {
        type: Boolean,
        default: false
      }
    },
    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    components: {
      MusicPlayIcon
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  $height-img: 90px;

  .cart-detail-item {
    position: relative;
    min-height: $height-img;
    color: rgba($color: #000000, $alpha: 0.85);
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    .item-left {
      position: absolute;
      left: 0px;
      top: 0;
      width: $height-img;
      height: $height-img;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .item-right {
      display: flex;

      .right {
        flex-shrink: 0;
      }
    }
  }

  @media screen and (min-width: $screen-width-md) {
    .item-right {
      padding-left: 110px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .cart-detail-item {
      .item-left {
        width: 63px;
        height: 63px;
      }

      .item-right {
        flex-direction: column;
        padding-left: 75px;
      }
    }
  }
</style>
